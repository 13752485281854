<template lang="pug">
.card
  .card-header
    p.card-header-title Qué hacer cuando la regla se cumpla
  .card-content
    div
      b-checkbox(v-model='rawValue.generateEvent' @input='onInput') Generar una notificación en la plataforma
    div
      b-checkbox(v-model='sendEmail' @input='sendEmailChanged') Enviar email
    .my-3.ml-5(v-if='sendEmail')
      b-field(label='Correos electrónicos' label-position='on-border' )
        b-taginput(
          v-model='rawValue.emailActions'
          field='emailActions'
          placeholder='Direcciones de correo electrónico'
          @input='onInput'
          :before-adding='beforeAddingEmail'
        )
    div
      b-checkbox(v-model='rawValue.turnOffEngine' type='is-danger') Apagar el vehículo
      p.alert-text(v-if='rawValue.turnOffEngine') Use esta funcionalidad con precaución, un vehículo apagado podría causar un accidente.
</template>

<script>
import { isEmailValid } from '@/utils/email'
import toastService from '@/services/toast.service'

const normalizeValue = value => {
  const rawValue = {
    ...value,
    ...(value.emailActions ? {} : { emailActions: [] })
  }
  return rawValue
}

export default {
  props: {
    value: { type: Object }
  },
  data () {
    const rawValue = normalizeValue(this.value)
    return {
      sendEmail: !!rawValue.emailActions.length,
      rawValue
    }
  },
  watch: {
    value () {
      const rawValue = normalizeValue(this.value)
      this.rawValue = rawValue
      this.sendEmail = !!rawValue.emailActions.length
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.rawValue)
    },
    sendEmailChanged () {
      this.rawValue.emailActions = []
    },
    beforeAddingEmail (tag) {
      if (!isEmailValid(tag)) {
        toastService.error(`${tag} no es un email válido`)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-text {
  color: red;
  font-size: 0.9em;
  border-left: solid 3px red;
  padding-left: 10px;
  margin-left: 30px;
}
</style>
